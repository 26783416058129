import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Paper = makeShortcode("Paper");
const SingleImage = makeShortcode("SingleImage");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "who-makes-the-rules"
    }}>{`Who makes the rules?`}</h1>
    <p>{`When making rules that are specific for each child in your family (e.g. an age-appropriate curfew), it is important to include that child in the discussion about creating the rules and the consequences for breaking them. Involving them in this way helps them understand these rules better. `}<em parentName="p">{`And`}</em>{` they would probably be more likely to accept them!`}</p>
    <p>{`When setting rules and consequences that apply to everyone in the family (such as how we treat each other), it’s important that `}<strong parentName="p">{`all family members`}</strong>{` are involved in the discussion.`}</p>
    <Paper mdxType="Paper">
  <h3>Starting family rules now?</h3>
  <p>
    If clear rules are established from an early age, your child is more likely to accept the rules than if they are
    established for the first time when they are older. If you are establishing family rules for the first time with
    your teenager, you may find your teenager pushes back against them initially. But it is in your teenager’s interest
    that you persist. Be patient with yourself and your teenager.
  </p>
    </Paper>
    <SingleImage smallGridSize={8} gridSize={8} src="/images/m3/Rules_oriental_fam_discussion.svg" alt="Family discussion" mdxType="SingleImage" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      